import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Autocomplete, Box, Collapse, IconButton, Skeleton, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { uniqueArrayOfObject } from '../helper/object';
import moment from "moment-timezone";
import Dropdown from "react-bootstrap/Dropdown";
import { MdCalendarMonth, MdOutlineDangerous, MdOutlineVerified } from 'react-icons/md';
import { DateRange } from "react-date-range";
import useDebounce from '../helper/useDebounce';
import { get_patient, get_patient_with_contacts } from '../redux/slice/patients';
import { create_brodcast_message, get_single_broadcast } from '../redux/slice/message_slice';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import Chip from '@mui/material/Chip';
import { Select, MenuItem } from '@mui/material';
import { AiOutlineUserAdd } from "react-icons/ai";
import { AiOutlineUserDelete } from "react-icons/ai";
import { get_facility } from '../redux/slice/facility';
import "../module_css/singalbroadcast.css"


const columns = [
    1, 2, 3, 4
]

const filterData = (data) => {
    const vals = data ? data?.map((it) => {
        return {
            _id: it._id,
            value: it?.name
        }
    }) : []
    return [{ _id: "null", value: "* No Facility" }, ...vals];
}

const recepients = [
    {
        name: "test01",
        phone: "76543794",
        received: "success"
    },
    {
        name: "test02",
        phone: "98676794",
        received: "failed"
    },
    {
        name: "test03",
        phone: "686767694",
        received: "success"
    },
    {
        name: "test04",
        phone: "98679564",
        received: "success"
    },
]




const familyRelations = [
    "Spouse/Partner",
    "Brother",
    "Sister",
    "Parent",
    "Child",
    "Sibling",
    "Guardian",
    "Grandparent",
    "Grandchild",
    "Aunt",
    "Uncle",
    "Niece",
    "Nephew",
    "Cousin",
    "Self"
];

const SingleBroadcast = () => {

    const [selectedRelations, setSelectedRelations] = useState([]);
    // console.log("lllll", selectedRelations)
    const relationsOptions = familyRelations.map((relation, index) => ({
        _id: index,
        value: relation,
    }));

    const navigate = useNavigate();
    const { broadcast_id } = useParams();
    const [open, setOpen] = useState({});

    const handleRowClick = (rowId) => {
        if (!rowId) return;
        setOpen((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
    };

    const {
        patientsWithContacts,
        loading,
        totalPatientsWithContacts
    } = useSelector((state) => state.patient);

    const {
        msg_loading,
        single_broadcast
    } = useSelector((state) => state.message);
    const [active, setActive] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDropdown, setIsDropdown] = useState(false);
    const [page, setPage] = useState(0);
    const [searchFilter, setSearchFilter] = useState('')
    const shift = useSelector((state) => state.shift);
    const profile = useSelector((state) => state.profile);
    const facility = useSelector(state => state.facility)
    const dispatch = useDispatch()
    const token = Cookies.get('token');
    const [searchQuery, setSearchQuery] = useState('');
    const [contactName, setContactName] = useState('');
    const [phone, setPhone] = useState('');
    const debouncedSearch = useDebounce(searchQuery, 500);
    const debouncedContactName = useDebounce(contactName, 500);
    const debouncedRelationship = useDebounce(selectedRelations, 500);
    const debouncedPhone = useDebounce(phone, 500);
    const listInit = {
        _id: '',
        value: ''
    }
    const [facilityList, setFacilityList] = useState([])



    // filter agency filter
    function sortArrayByValue(array) {
        array?.sort((a, b) => {
            const valueA = a.value?.toLowerCase();
            const valueB = b.value?.toLowerCase();
            if (valueA < valueB) return -1;
            if (valueA > valueB) return 1;
            return 0;
        });
        return array;
    }
    // console.log("handalFilterhandalFilter",searchFilter)

    useEffect(() => {
        if (!facility?.status && !facility?.loading) {
            dispatch(get_facility({ token }))
        }
        if (profile?.data?.roles[0]?.name == 'admin') {
            const list = filterData(facility?.facility_data)
            setFacilityList(sortArrayByValue(list))
        }
        else if (profile?.data?.roles[0]?.name == 'agency_user' && !facilityList?.length) {
            const facility = shift?.shift?.map(item => {
                return { ...item.facility_id }
            })
            const uuniqueList = uniqueArrayOfObject(facility, '_id')
            const list = filterData(uuniqueList)
            setFacilityList(sortArrayByValue(list))
        }
        else {
            const list = filterData(profile?.data?.facility)
            setFacilityList(sortArrayByValue(list))
        }
    }, [profile, facility])



    const handalFilter = (searchFilter, page) => {
        return {
            startdate: searchFilter?.startdate ? searchFilter?.startdate : '',
            enddate: searchFilter?.enddate ? searchFilter?.enddate : '',
            disStartdate: searchFilter?.disStartdate ? searchFilter?.disStartdate : '',
            disEnddate: searchFilter?.disEnddate ? searchFilter?.disEnddate : '',
            facility: searchFilter?.facility ? searchFilter?.facility : '',
            selectedRelations: searchFilter?.selectedRelations ? searchFilter?.selectedRelations : '',
            active: searchFilter?.active ? searchFilter?.active : '',
            page: page + 1,
            limit: 10
        }
    }


    useEffect(() => {
        dispatch(get_single_broadcast({ token, broadcast_id }));
    }, [token])


    // date handle
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });
    // date handle
    const [dischargeRange, setDischargeRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });

    // handal Date 
    const handleDate = (ranges) => {
        const End = new Date(
            new Date(ranges.selection.endDate).getTime() + 86400000
        );
        const stdate = new Date(ranges.selection.startDate);
        const endate = new Date(End);
        setSelectionRange(ranges.selection);
        setSearchFilter({
            ...searchFilter,
            startdate: moment(stdate).format("YYYY-MM-DD"),
            enddate: moment(endate).format("YYYY-MM-DD")
        })
    };

    // handleDischargeDate
    const handleDischargeDate = (ranges) => {
        const End = new Date(
            new Date(ranges.selection.endDate).getTime() + 86400000
        );
        const stdate = new Date(ranges.selection.startDate);
        const endate = new Date(End);
        setDischargeRange(ranges.selection);
        setSearchFilter({
            ...searchFilter,
            disStartdate: moment(stdate).format("YYYY-MM-DD"),
            disEnddate: moment(endate).format("YYYY-MM-DD")
        })
    };

    const handleRelationChange = (_, value) => {
        const relationId = value[value.length - 1]?._id;
        const isSelected = selectedRelations.some((relation) => relation._id === relationId);

        if (isSelected) {
            const updatedRelations = selectedRelations.filter(relation => relation._id !== relationId);
            setSelectedRelations(updatedRelations);
        } else {
            setSelectedRelations(value);
        }
    };

    const handalSearchById = (name, value) => {
        setSearchFilter({
            ...searchFilter,
            [name]: value
        })
    }
    const handalSearchBydischargedate = (discharge_date, value) => {
        setSearchFilter({
            ...searchFilter,
            [discharge_date]: value
        })
    }



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleToggle = (event) => {
        const newActiveState = event.target.checked;
        setActive(newActiveState);
        handalSearchBydischargedate(newActiveState); // Replace with your function
    };
    let facilitiesSelected = [];
    if (single_broadcast) {
        facilitiesSelected = single_broadcast?.data?.broadcast?.facilities?.map((item) => {
            return {
                _id: item._id,
                value: item?.name
            }
        })
    }


    return (
        <>
            <div className="contact_main_div">
                <Sidebar />
                <div className="head-div">
                    <div className="head_header singleheaddiv">
                        <h2 ><FaArrowLeft size={30} style={{
                            marginRight: "6px",
                            cursor: "pointer",
                        }} onClick={() => {
                            navigate(-1);
                        }} />Broadcast Summary</h2>
                        <div className="admin-section singleadmin">
                            <span> {profile?.status ? profile?.data?.first_name + " " + profile?.data?.last_name : ""}</span>
                        </div>
                    </div>
                    <div className='filer_boxs'>
                        <div className='filer_box' style={{
                            padding: '10px',
                            paddingBottom: 0,
                            display: "flex",
                            flexDirection: "column",

                        }}>
                            <div className="px-2 d-flex fs-5">
                                Filters:
                            </div>
                            <div className="px-2 py-2 d-flex gap-3">
                                {single_broadcast?.data?.broadcast?.filters?.patient_name &&
                                    <TextField id="outlined-basic" label="" variant="outlined" size="small" sx={{
                                        width: (single_broadcast?.data?.broadcast?.filters?.facility?.length > 0) ? "50%" : "100%"
                                    }}
                                        value={single_broadcast?.data?.broadcast?.filters?.patient_name}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />}
                                {single_broadcast?.data?.broadcast?.filters?.facility?.length > 0 &&
                                    <Tooltip title={single_broadcast?.data?.broadcast?.filters?.facility?.map(item => item?.value)?.join(" , ") || "No facilities were selected"} >
                                        <div className='border rounded-1 d-flex px-2 align-items-center' style={{
                                            width: single_broadcast?.data?.broadcast?.filters?.patient_name ? "50%" : "100%",
                                            height: "50px"
                                        }} >
                                            Selected Facilities : {single_broadcast?.data?.broadcast?.filters?.facility?.length || 0}
                                        </div>
                                    </Tooltip>}
                            </div>

                            <div className='px-2 py-2 d-flex gap-3'>
                                {(single_broadcast?.data?.broadcast?.filters?.startdate && single_broadcast?.data?.broadcast?.filters?.enddate) &&
                                    <div className={`${single_broadcast?.data?.broadcast?.filters?.selectedRelations?.length > 0 ? "w-50" : "w-100"}`}>
                                        <div className="Admission_date">
                                            <span className='d-flex justify-content-lg-start align-items-center gap-2'>
                                                <MdCalendarMonth />
                                                {single_broadcast?.data?.broadcast?.filters?.startdate && single_broadcast?.data?.broadcast?.filters?.enddate ? (
                                                    <span className="date-icon-span">
                                                        {single_broadcast?.data?.broadcast?.filters?.startdate} / {single_broadcast?.data?.broadcast?.filters?.enddate}
                                                    </span>
                                                ) : (
                                                    <span className="date-icon-span fs-6">Admission Date</span>
                                                )}
                                            </span>
                                        </div>
                                    </div>}
                                {single_broadcast?.data?.broadcast?.filters?.selectedRelations?.length &&
                                    <Tooltip title={single_broadcast?.data?.broadcast?.filters?.selectedRelations?.map(item => item?.value)?.join(" , ") || "No relations were selected"} >
                                        <div className='border rounded-1 d-flex px-2 align-items-center' style={{
                                            width: (single_broadcast?.data?.broadcast?.filters?.startdate && single_broadcast?.data?.broadcast?.filters?.enddate) ? "50%" : "100%",

                                            height: "50px"
                                        }} >
                                            Selected Relations : {single_broadcast?.data?.broadcast?.filters?.selectedRelations?.length || 0}
                                        </div>
                                    </Tooltip>}
                            </div>
                            <div className='p-2 d-flex align-content-center' style={{
                                width: "100%"
                            }} >
                                <div className='border rounded-1 d-flex px-2 align-items-center' style={{
                                    width: "100%",
                                    height: "50px"
                                }} >
                                    {single_broadcast?.data?.broadcast?.filters?.selectedRelations?.active ? "Inactive" : "Active"}
                                </div>
                            </div>
                        </div>
                        <div className='filer_box' style={{
                            padding: '10px',
                            paddingBottom: 0,
                            display: "flex",
                            flexDirection: "column",
                        }}>

                            <div className="px-2 py-2  " style={{
                                height: '80%',
                            }}>
                                {/* <p>Broadcast Message</p> */}
                                <p className='fs-8' style={{ marginBottom: 0 }} >Message:</p>
                                <div className=' py-2 px-1 mt-1  border rounded-2 ' style={{
                                    maxHeight: "120px",
                                    overflowY: "scroll",
                                }}>
                                    {!msg_loading ? single_broadcast?.data?.broadcast?.message : <div className='text-center fs-6 py-2'>Loading...</div>}
                                </div>
                                <p className='fs-8 mt-4' style={{ marginBottom: 0 }} >Response:</p>
                                <div className="d-flex w-100  rounded-2 gap-2">
                                    <div className='d-flex justify-content-evenly w-100  py-2 px-1 border rounded-2 border-success'>
                                        <span><b>Sent to:</b> {single_broadcast?.data?.messages?.filter((it) => it?.status == true)?.length}</span>
                                    </div>
                                    <div className='d-flex justify-content-evenly w-100  py-2 px-1 border rounded-2 border-danger'>
                                        <span> <b>Failed for:</b> {single_broadcast?.data?.messages?.filter((it) => it?.status == false)?.length}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=' w-100  d-flex align-items-center '>
                        <p className='boradcast  '>Total recipients included in the broadcast:  {!msg_loading ? single_broadcast?.data?.messages?.length || 0 : "..."} </p>
                    </div>

                    <div className='filter_table'>
                        <TableContainer component={Paper} sx={{ borderRadius: "15px" }}>
                            {!msg_loading ? (
                                <>
                                    <Table sx={{ minWidth: 650 }}>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell align="left" sx={{ fontWeight: 700 }}>S.No.</TableCell>
                                                <TableCell sx={{ fontWeight: 700 }} align="left">Recepient name</TableCell>
                                                <TableCell align="center" sx={{ fontWeight: 700 }}>Phone</TableCell>
                                                <TableCell align="center" sx={{ fontWeight: 700 }}>Message received</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {single_broadcast?.data?.messages?.map((broadcast, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell align="left">{index + 1}</TableCell>
                                                        <TableCell align="left">{broadcast?.Patient_contact?.name}</TableCell>
                                                        <TableCell align="center">
                                                            {broadcast?.Patient_contact?.phone}
                                                        </TableCell>
                                                        <TableCell align="center">{broadcast?.status ? <MdOutlineVerified color='green' size={24} />
                                                            :
                                                            <Tooltip title={broadcast?.reason} placement='top'>
                                                                <span style={{ cursor: "pointer" }} >
                                                                    <MdOutlineDangerous color='red' size={24} />
                                                                </span>
                                                            </Tooltip>
                                                        }</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </>
                            ) : (
                                <div>
                                    <div className="py-2 px-2">
                                        <Skeleton variant="rounded" height={36} />
                                    </div>
                                    <div className="d-flex justify-content-between  py-3 px-2">
                                        {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                    </div>
                                    <div className="d-flex justify-content-between  py-3 px-2">
                                        {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                    </div>
                                    <div className="d-flex justify-content-between  py-3 px-2">
                                        {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                    </div>
                                    <div className="d-flex justify-content-between  py-3 px-2">
                                        {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                    </div>
                                </div>
                            )}
                        </TableContainer>
                    </div>
                </div>
            </div>

        </>
    )
}
export default SingleBroadcast