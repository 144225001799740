import React from "react";
import { BiSolidTime } from "react-icons/bi";
import Clock from "../asset/Vector (2).png";
import "../module_css/ragister.css"
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <div className="nav-main">
        <div className="nav-inner">
          {/* <BiSolidTime size={40} color="green" /> 
          <*/}
          <Link to={"/"}><img src={Clock} /></Link>
          <span className="timetra-heading">
            <b>Time </b>Tracking
          </span>
        </div>
      </div>
    </>
  );
};

export default Navbar;
