import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apis } from "../../apis";
import axios from "axios";

export const get_units = createAsyncThunk('get_units', async ({ token }, { rejectWithValue }) => {
    try {
        try {
            let headers = {
                token: token
            }
            const { data } = await axios.get(`${apis.GET_UNIT}`, { headers })
            return data
        } catch (error) {
            
            return rejectWithValue(error.response)
           
        }
    } catch (error) {

    }
})
const unitsSlice = createSlice({
    name: 'get_units',
    initialState: {
        status: false,
        loading: false,
        error: '',
        units_data: [],
        
    },
    extraReducers: (builder) => {
        builder.addCase(get_units.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(get_units.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = true;
            state.loading = false;
        });
        builder.addCase(get_units.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.units_data = null;
        });
    }
});

export default unitsSlice.reducer;
