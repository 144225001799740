import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apis } from "../../../apis";
import axios from "axios";
import { PiNutFill } from "react-icons/pi";

export const get_partial_kiosk_punches = createAsyncThunk("partial_kiosk_punches", async ({ filter, debouncedMappingId, debouncedLocationId, token, page, limit, emp_search, search, agency_id, facility, facility_mapping_id, location_id, startdate, punch_string,enddate, debouncedPunchstring },


    { rejectWithValue }) => {
    try {
        let headers = {
            token: token
        }
        const { data } = await axios.post(`${apis.PARTIAL_KIOSK_PUNCHES}`, {
            caregiver_id: search,
            agency_id: agency_id,
            facility_id: facility,
            location_id: debouncedLocationId,
            startdate: startdate,
            enddate:enddate,
            punch_string: debouncedPunchstring,
            facility_mapping_id: debouncedMappingId,
            page: page + 1,
            limit: limit || 5
        }, { headers })

        return data

    } catch (error) {
        return rejectWithValue(error.response)
    }

})


const partial_kiosk_punches = createSlice({
    name: "partial_kiosk_punches",
    initialState: {
        loading: false,
        error: null,
        data: null,
        status: false,
        total: null,
        totalPages: null

    },

    extraReducers: (builder) => {
        builder.addCase(get_partial_kiosk_punches.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(get_partial_kiosk_punches.fulfilled, (state, action) => {
            state.data = action.payload?.punches;
            state.status = true
            state.loading = false
            state.total = action.payload?.totalDocuments
            state.totalPages = action.payload?.totalPages
        });
        builder.addCase(get_partial_kiosk_punches.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.data = null;
        });
    }
})

const partial_kiosk_punches_slice = partial_kiosk_punches.reducer
export default partial_kiosk_punches_slice
