import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import axios from "axios";
import { apis } from "../../apis";
import { Skeleton, TextField } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import SelectSingle from "../../components/input/Select";
import { toast } from "react-toastify";
import TableSection from "./table";
import EnhancedTable from "./EnhancedTable";
import { get_twilio } from "../../redux/slice/createtwilio";
import { get_twiliofacilityphone } from "../../redux/slice/twiliofacilityphone";
import "../../module_css/twiliofacilityphonetable.css"
const columns = [
    { id: 'id', label: 'Sr No', isSort: true },
    { id: 'name', label: 'Name', isSort: true },
    { id: 'phone', label: 'Phone', isSort: true },
    { id: 'twilio_creds', label: 'Twilio Creds', isSort: true },
    { id: 'action', label: 'Action', align: 'center', isSort: false },
];

export default function TwilioFacilityPhoneTable({ }) {
    const [updateModel, setUpdateModel] = useState(false);
    const [FormData, setFormData] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const twiliocredid = useSelector(state => state.twilio)
    const twiliofacilityphone = useSelector(state => state.twiliofacilityphone);
    const dispatch = useDispatch();
    const token = Cookies.get("token");
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false) 
    const [isSkeleton, setIsSkeleton] = useState(true) 
    const [page, setPage] = useState(0)
   
    useEffect(() => {
        if (!twiliofacilityphone?.status && !twiliofacilityphone?.loading) {
            dispatch(get_twiliofacilityphone({ token }));
        }
        if (twiliofacilityphone?.status) {
            console.log('Fetched data:', twiliofacilityphone.data?.data);  
            setIsSkeleton(false);
        }
        if (!twiliocredid?.status) {
            dispatch(get_twilio({ token }))
        }
    }, [twiliofacilityphone]); 

    useEffect(() => {
        if (twiliofacilityphone?.data?.data?.length > 0) {
            const dataRows = twiliofacilityphone.data?.data?.map((item, index) => ({
                id: twiliofacilityphone.data?.data?.length - index,
                name:item?.name,
                phone: item.phone,
                twilio_creds: item?.twilio_creds?.name,
                twiliocredid: item?.twiliocredid,
                action: (
                    <button className="btn" onClick={() => handleModel(item)}>
                        <TbEdit />
                    </button>
                ),
            }));
            // console.log('asdfasdf: ',dataRows)
            setRows(dataRows.reverse());
        }else{
            "No data found"
        }
        
    }, [twiliofacilityphone]);

    const hadalChangeModal = ({ target }) => {
        const { name, value } = target;
        setFormData({
            ...FormData,
            [name]: value,
        });
    };


    const handalChange = ({ target }) => {
        const { name, value } = target;
        setFormData({
            ...FormData,
            [name]: value,
        });
    };

    function handleModel(item) {
        setFormData(item);
        setUpdateModel(true);
    };

    const filteredRows = [...rows]
        .filter(row => {
            const searchFields = [row.phone, row.twilio_creds];
            return searchFields.some(field => field?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
        })
        .map((row, index) => ({
            ...row,
            id: index + 1, // Start the ID from 1
        }));

    const handleUpdate = async () => {
        try {
            setLoading(true)
            const updata = await axios.put(
                apis.UPDATE_TWILIOFACILITYPHONE,
                {
                    id: FormData._id,
                    name:FormData?.name,
                    phone: FormData?.phone,
                    twilio_creds: FormData?.twilio_creds,
                },
                {
                    headers: {
                        token: token,
                    },
                }
            );
            setUpdateModel(false);
            toast.success("twilio facility phone Successfully updated..", {
                position: "top-right",
            });
            dispatch(get_twiliofacilityphone({ token }))
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        } catch (error) {
            toast.error("twilio update failed..", {
                position: "top-right",
            });
        } finally {
            setLoading(false)
        }
    };
console.log("asdfsadf: ",FormData)
    return (
        <>
            {
                isSkeleton ? <div className="d-flex justify-content-end">
                    <Skeleton animation="wave" height={60} width={200} />
                </div> : <div className="container text-end mb-2 searchuser ">
                    <TextField
                        label="Search"
                        type="search"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={(e) => {
                            setPage(0)
                            setSearchQuery(e.target.value)
                        }}
                    />
                </div>
            }
            <EnhancedTable status={twiliofacilityphone?.status} columns={columns} rows={filteredRows} page={page} setPage={setPage} />
            <Modal
                show={updateModel}
                onHide={() => setUpdateModel(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Update Twilio Facility Phone
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="row ">
                <div className="col-sm-6 text-center twiliophonefield">
                            <TextField
                                id="outlined-required"
                                label="Name"
                                value={FormData?.name}
                                onChange={hadalChangeModal}
                                name="name"
                                sx={{ width: "100%" }}
                            />
                        </div>
                        <div className="col-sm-6 text-center twiliophonefield ">
                            <TextField
                                id="outlined-required"
                                label="Phone"
                                value={FormData?.phone}
                                onChange={hadalChangeModal}
                                name="phone"
                                sx={{ width: "100%" }}
                            />
                        </div>
                        <div className="col-md-12 mb-12 mt-4 text-center twiliophonesingle">
                            <SelectSingle data={twiliocredid?.twilio_data} value={ typeof FormData?.twilio_creds == "string" ? FormData?.twilio_creds :FormData?.twilio_creds?._id} name="twilio_creds" label="Select Twilio" handleChange={handalChange} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-center container">
                        {
                            loading ? <Button variant="success" type="button">Loading...</Button> : <Button variant="success" onClick={handleUpdate}>Update Facility Phone</Button>
                        }
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}