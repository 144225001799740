import React, { useEffect, useState } from 'react'
import Navbar2 from '../NavBar2'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import ShiftFilter from '../../components/shift/filter'
import EnhancedTable from '../../components/table/EnhancedTable'
import { Backdrop, CircularProgress, Skeleton, TextField } from '@mui/material'
import axios from 'axios'
import { FaCheckCircle } from "react-icons/fa";

import { apis } from '../../apis'
import DynamicTable from '../../components/table/dynamicTable'
import moment from 'moment'
import { FaCircleExclamation } from 'react-icons/fa6'
import { Modal } from 'react-bootstrap'

const columns = [
    { id: 'id', label: 'Sr No', isSort: true },
    { id: 'employee_name', label: 'Employee Name', isSort: true },
    { id: 'facility', label: 'Facility', isSort: true },
    { id: 'agency', label: 'Agency', isSort: true },
    { id: 'position', label: 'Position', align: 'center', isSort: true, textTransform: "uppercase" },
    { id: 'shift_start', label: 'Shift Start', align: 'center', isSort: true },
    { id: 'shift_end', label: 'Shift End', align: 'center', isSort: true },
    { id: 'shift_duration', label: 'Shift Duration', align: 'center', isSort: true },
    { id: 'scan_time', label: 'Scan Time', align: 'center', isSort: false },
    { id: 'punch_string', label: 'Kiosk Punch', align: 'center', isSort: false },
    { id: 'details', label: 'Details', isSort: false },
    // { id: 'action', label: 'Action', align: 'center' },
];

const ShiftDashboard = () => {
    const profile = useSelector(state => state.profile)
    const shift = useSelector((state) => state?.shift);
    const [rows, setRows] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [isSkeleton, setIsSkeleton] = useState(true)
    const [modalData, setModalData] = useState({})
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    useEffect(() => {
        const dataRows = shift?.shift?.map((it, key) => {
            const scan_date = moment(it?.scan_time);
            return {
                id: key + 1,
                employee_name: `${it?.caregiver?.lastName} ${it?.caregiver?.firstName}`,
                facility: it?.facility_id?.name,
                agency: it?.agency?.name,
                position: it.position,
                shift_start: `${it?.startShift?.date} \n ${it?.startShift?.time}`,
                shift_end: `${it?.endShift?.date} \n ${it?.endShift?.time}`,
                shift_duration: `${it.duration}`,
                scan_time: `${it?.scan_time ? scan_date.utc().format('DD/MM/YYYY') + " " + scan_date.utc().format('h:mm A') : "N/A"}`,
                punch_string: it?.shift_in_id?.punch_string ? <button className="btn"><FaCheckCircle size={20} color='#345d3b' /></button> : <>N/A</>,
                details: <button className="btn" onClick={() => handleShowModal(it)}><FaCircleExclamation /></button>
                // action: <button className='btn' onClick={() => handalDrop(it)}>Drop</button>
            }
        })
        setRows(dataRows)
    }, [shift])


    async function handleShowModal(payload) {
        const punch_string = payload?.request_data?.punch_string.split('-')
        const date = new Date(payload?.request_data?.datetime);

        // Format the date and time using toLocaleString
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        const formattedDate = date.toLocaleString('en-GB', options).replace(',', '-');
        const data = {
            employee_name: `${payload?.caregiver?.lastName} ${payload?.caregiver?.firstName}` || "",
            facility: payload?.facility_id?.name || '',
            agency: payload?.agency?.name || '',
            punch_string: payload?.shift_in_id?.punch_string || 'N/A',
            position: payload?.position || "",
            shift_start: `${payload?.startShift?.date} \n ${payload?.startShift?.time}` || "",
            shift_end: `${payload?.endShift?.date} \n ${payload?.endShift?.time}` || "",
            shift_duration: payload?.duration || "",
            scan_time: payload?.scan_time || "",


        }
        setModalData(data)
        setModalShow(true)
    }

    let token = Cookies.get("token");
    async function handalDrop(payload) {
        try {
            await axios.delete(`${apis.DROP_SHIFT}/${payload?._id}`, {
                headers: {
                    token
                }
            })
        } catch (error) {
            console.log("error : ", error)
        }
    }

    return (
        <>
            <Navbar2 />
            {
                shift?.loading && <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }

            <div className="container py-4">
                <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-4 col-md d-flex align-items-center">
                        <NavLink to={"/landing-page"} className="text-dark">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                        </NavLink>
                        <p className="admin-name-choose-sel rounded mb-0 mx-2 py-2 px-3">
                            Shifts
                        </p>
                    </div>

                    <div className="col-8 col-md d-flex justify-content-end">
                        {profile?.data?.roles[0]?.name === "admin" && (
                            <>
                                <NavLink
                                    to="/partial-kiosk-punches"
                                    className="admin-name-choose-sel text-decoration-none py-2 mx-1 px-3 rounded"
                                >
                                    Partial Punches
                                </NavLink>
                                <NavLink
                                    to="/admin"
                                    className="admin-name-choose-sel text-decoration-none py-2 mx-1 px-3 rounded"
                                >
                                    Admin Panel
                                </NavLink>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="dash-main py-3">
                <div className="container shift-filter">
                    <ShiftFilter isSkeleton={isSkeleton} setIsSkeleton={setIsSkeleton} page={page} setPage={setPage} limit={rowsPerPage} />
                </div>
                <div className="container py-3">
                    {
                        !isSkeleton ? <DynamicTable status={shift?.status} columns={columns} rows={rows} page={page} setPage={setPage} totalPage={shift?.pagination?.total} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> :
                            <div>
                                <div className="py-2">
                                    <Skeleton variant="rounded" height={36} />
                                </div>
                                <div className="d-flex justify-content-between  py-2">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                                <div className="d-flex justify-content-between  py-3">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                                <div className="d-flex justify-content-between  py-3">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                                <div className="d-flex justify-content-between  py-3">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                            </div>
                    }
                </div>
            </div>

            <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Shifts Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="row">
                            <div className="col-md-6 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Employee Name"
                                    value={modalData?.employee_name}
                                    name="name"
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Agency Name"
                                    value={modalData?.agency || 'N/A'}
                                    name="name"
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Facility Name"
                                    value={modalData?.facility}
                                    name="name"
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Position"
                                    value={modalData?.position}
                                    name="name"
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Shift start"
                                    value={modalData?.shift_start}
                                    name="name"
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Shift end"
                                    value={modalData?.shift_end}
                                    name="name"
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Shift duration"
                                    value={modalData?.shift_duration}
                                    name="name"
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Scan time"
                                    value={`${modalData?.scan_time ? moment(modalData?.scan_time).utc().format('DD/MM/YYYY hh:mm A') : "N/A"}`}
                                    name="name"
                                    readOnly
                                />
                            </div>
                            <div className="col-md-12 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Punch String"
                                    value={modalData?.punch_string}
                                    name="name"
                                    readOnly
                                />
                            </div>

                        </div>
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-success px-5" onClick={() => setModalShow(false)} style={{ background: "#345d3b" }}>
                                close
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ShiftDashboard