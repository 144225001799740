import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apis } from "../../apis";
import axios from "axios";

export const get_relationship = createAsyncThunk('get_relationship', async ({ token }, { rejectWithValue }) => {
    try {
        try {
            let headers = {
                token: token
            }
            const { data } = await axios.get(`${apis.GET_RELATIONSHIP}`, { headers })
            return data
        } catch (error) {
            
            return rejectWithValue(error.response)
           
        }
    } catch (error) {

    }
})
const relationship = createSlice({
    name: 'get_relationship',
    initialState: {
        status: false,
        loading: false,
        error: '',
        relationship_data: [],
        
    },
    extraReducers: (builder) => {
        builder.addCase(get_relationship.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(get_relationship.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = true;
            state.loading = false;
        });
        builder.addCase(get_relationship.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.relationship_data = null;
        });
    }
});

export default relationship.reducer;
