import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import React, { useEffect, useState, useCallback } from 'react';
import { MdCalendarMonth } from "react-icons/md";
import { PiArrowClockwiseBold } from "react-icons/pi";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment-timezone";
import { DateRange } from "react-date-range";
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { get_agency } from '../../redux/slice/agency';
import Cookies from 'js-cookie';
import { uniqueArrayOfObject } from '../../helper/object';
import { get_facility } from '../../redux/slice/facility';
import { get_all_caregivers } from '../../redux/slice/caregivers';
import { get_partial_kiosk_punches } from '../../redux/slice/partial-kiosk-punches';
import useDebounce from '../../helper/useDebounce';
import { CiFilter } from 'react-icons/ci';
import "../../module_css/Partial_kiosk.css"

const filterData = (data = []) => {
    return data?.map((it) => ({
        _id: it._id,
        value: it?.name || '',
    }));
};

const caregiverfilter = (data = []) => {
    return data?.map((it) => ({
        _id: it._id,
        value: `${it?.lastName || ''} ${it?.firstName || ''}`,
    }));
};

const ShiftFilter = ({ isSkeleton, setPage, page, limit }) => {
    const agency = useSelector((state) => state.agency || {});
    const profile = useSelector((state) => state.profile || {});
    const facility = useSelector((state) => state.facility || {});
    const partial_punch = useSelector((state) => state.partial_kiosk_punches || {});
    const all_caregivers = useSelector((state) => state.all_caregivers || {});

    const dispatch = useDispatch();
    const token = Cookies.get('token');
    const listInit = { _id: '', value: '' };

    const [agencyList, setAgencyList] = useState([listInit]);
    const [facilityList, setFacilityList] = useState([]);
    const [employee, setEmployee] = useState([listInit]);

    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });

    const sortArrayByValue = (array = []) => {
        return array.sort((a, b) => {
            const valueA = a.value.toLowerCase();
            const valueB = b.value.toLowerCase();
            return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        });
    };
    const [locationIdInput, setLocationIdInput] = useState('');
    const [facilityMappingId, setfacilityMappingId] = useState('');
    const [punchString, setPunchString] = useState('')
    const [searchFilter, setSearchFilter] = useState({
        location_id: '',
        facility_mapping_id: '',
        punch_string: ''
    });

    const debouncedLocationId = useDebounce(locationIdInput, 500);
    const debouncedMappingId = useDebounce(facilityMappingId, 500);
    const debouncedPunchstring = useDebounce(punchString, 500);
    useEffect(() => {
        setSearchFilter((prevFilter) => ({
            ...prevFilter,
        }));
    }, []);

    const handleFilterChange = (name, value) => {
        setPage(0);
        if (name === 'location_id') {
            setLocationIdInput(value);
        } else if (name === 'facility_mapping_id') {
            setfacilityMappingId(value);
        } else if (name === 'punch_string') {
            setPunchString(value);
        }
        else {
            setSearchFilter((prevFilter) => ({
                ...prevFilter,
                [name]: value,
            }));
        }
    };

    useEffect(() => {
        if (agency.agency) {
            const list = filterData(agency.agency);
            setAgencyList(sortArrayByValue(list));
        }

        if (!agency.status && !agency?.loading) {
            dispatch(get_agency({ token, user: profile?.data?._id }));
        }
    }, [agency.status]);



    useEffect(() => {
        if (!facility?.status && !facility?.loading) {
            dispatch(get_facility({ token }));
        }
        if (profile?.data?.roles?.[0]?.name === 'admin') {
            const list = filterData(facility?.facility_data || []);
            setFacilityList(sortArrayByValue(list));
        } else if (profile?.data?.roles?.[0]?.name === 'agency_user' && !facilityList?.length) {
            const facility = profile?.shift?.map((item) => item.facility_id || {});
            const uniqueList = uniqueArrayOfObject(facility, '_id');
            const list = filterData(uniqueList);
            setFacilityList(sortArrayByValue(list));
        } else if (profile?.data?.roles?.[0]?.name === 'normal' && !facilityList?.length) {
            const list = filterData(profile?.data?.facility || []);
            setFacilityList(sortArrayByValue(list));
        } else {
            const list = filterData(facility?.facility_data || []);
            setFacilityList(sortArrayByValue(list));
        }
    }, [profile, facility]);


    useEffect(() => {
        if (!all_caregivers?.status && !all_caregivers?.loading) {
            dispatch(get_all_caregivers({ token }));
        }
        if (all_caregivers?.status) {
            const list = caregiverfilter(all_caregivers?.data || []);
            setEmployee(sortArrayByValue(list));
        }
    }, [all_caregivers?.status]);

    useEffect(() => {
        const list = filterData(agency.agency)
        setAgencyList(sortArrayByValue(list))
        if (!agency.status && !agency?.loading) {
            dispatch(get_agency({ token, user: profile?.data?._id }))
        }
    }, [agency.status])

    const formInit = {
        emp_search: listInit,
        startdate: '',
        enddate: '',
        facility: listInit,
        agency: listInit
    }

    const filterPayload = (page) => {

        return {
            token,
            startdate: searchFilter?.startdate || '',
            enddate: searchFilter?.enddate || '',
            agency_id: searchFilter?.agency?._id || '',
            location_id: searchFilter?.location_id || '',
            punch_string: searchFilter?.punch_string || '',
            facility_mapping_id: searchFilter?.facility_mapping_id || '',
            facility: searchFilter?.facility?._id || '',
            search: searchFilter?.emp_search?._id || '',
            page: page,
            debouncedLocationId: debouncedLocationId,
            debouncedMappingId: debouncedMappingId,
            debouncedPunchstring: debouncedPunchstring,
            limit: limit,
        }


    };

    useEffect(() => {
        if (profile?.status && partial_punch?.status) {
            const filter = filterPayload(page)
            setPage(page)
            dispatch(get_partial_kiosk_punches(filter))
        }
    }, [searchFilter, page, debouncedLocationId, debouncedMappingId, debouncedPunchstring])

    const handleDate = (ranges) => {
        const End = new Date(
            new Date(ranges.selection.endDate).getTime() + 86400000
        );
        const stdate = new Date(ranges.selection.startDate);
        const endate = new Date(End);
        setSelectionRange(ranges.selection);
        setSearchFilter({
            ...searchFilter,
            startdate: moment(stdate).format("YYYY-MM-DD"),
            enddate: moment(endate).format("YYYY-MM-DD")
        })
    };

    const handalClearFilter = () => {
        setSearchFilter('')
        setfacilityMappingId('')
        setLocationIdInput('')
        setPunchString('')
    }

    return (
        <div>
            <div className="d-flex justify-content-between  custom2">
                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        {isSkeleton ? <div className="py-2"><Skeleton variant="rounded" width={140} height={30} /></div> : <h4>Partial Punches</h4>}
                        <div className="d-flex align-items-center mb-3">
                            {isSkeleton ? <Skeleton variant="rounded" width={220} height={26} /> : <div>Here is a list of all Partial Punches</div>}
                        </div>
                    </div>
                </div>
                    <div className="  d-flex datefield">
                        {isSkeleton ? <div className="px-2 ">
                            <Skeleton variant="rounded" width={100} height={36} />
                        </div> :

                            <div className="date-pos">

                                <div className="date-fltr">
                                    <span>
                                        <MdCalendarMonth />
                                        {searchFilter?.startdate && searchFilter?.enddate ? (
                                            <span className="date-icon-span">
                                                {searchFilter?.startdate} / {searchFilter?.enddate}
                                            </span>
                                        ) : (
                                            <span className="date-icon-span">Date</span>
                                        )}
                                    </span>
                                </div>
                                <div className="col-xl-3  col-lg-4 col-md-4 col-sm-6 mt-2 mt-sm-0 button">
                                    <Dropdown className="dash-main-filter">
                                        <Dropdown.Toggle style={{ height: "100%", width: "183%", }} ></Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <DateRange ranges={[selectionRange]} onChange={handleDate} />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>}
                        {
                            isSkeleton ? <div className="px-2">
                                <Skeleton variant="rounded" width={100} height={36} />
                            </div> : <>
                                <div className="date-fltr" onClick={handalClearFilter}>
                                    <span>
                                        <i className='px-1' style={{ color: "#4C7153" }}><PiArrowClockwiseBold /></i>
                                        clear filter
                                    </span>
                                </div>
                            </>
                        }
                    </div>
               
            </div>
            <div className="filter-right d-flex align-items-center justify-content-center ">
                <div className=''>
                    {
                        isSkeleton ? <Skeleton variant="rounded" width={100} height={36} /> : <>
                            <CiFilter size={23} />
                            <span className="filter-icon-span">Filter by:</span>
                        </>
                    }
                </div>
                {isSkeleton ? <div className="px-2">
                    <Skeleton variant="rounded" width={100} height={36} />
                </div> : <div className="px-2">
                    <Autocomplete
                        id="employee-autocomplete"
                        size="small"
                        options={employee}
                        sx={{ width: 260 }}
                        value={searchFilter?.emp_search || listInit}
                        onChange={(_, value) => handleFilterChange('emp_search', value)}
                        getOptionLabel={(option) => option.value}
                        isOptionEqualToValue={(option, value) => option._id === value._id} // Ensures uniqueness by comparing _id
                        renderInput={(params) => <TextField {...params} label="Search Employee" />}
                        renderOption={(props, option) => <li {...props} style={{ cursor: 'pointer' }} key={option._id} className="py-1 ps-3 menu-drop-item"> {option.value} </li>}

                    />
                </div>}
                {isSkeleton ? <div className="px-2">
                    <Skeleton variant="rounded" width={100} height={36} />
                </div> : <div className="px-2">
                    <Autocomplete
                        id="agency-autocomplete"
                        size="small"
                        options={agencyList}
                        sx={{ width: 220 }}
                        value={searchFilter?.agency || listInit}
                        onChange={(_, value) => handleFilterChange('agency', value)}
                        getOptionLabel={(option) => option.value}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        renderInput={(params) => <TextField {...params} label="Search Agency" />}
                        renderOption={(props, option) => <li {...props} style={{ cursor: 'pointer' }} key={option._id} className="py-1 ps-3 menu-drop-item"> {option.value} </li>}

                    />
                </div>}
                {isSkeleton ? <div className="px-2">
                    <Skeleton variant="rounded" width={100} height={36} />
                </div> : <div className="px-2">
                    <Autocomplete
                        id="facility-autocomplete"
                        size="small"
                        options={facilityList}
                        sx={{ width: 220 }}
                        value={searchFilter?.facility || listInit}
                        onChange={(_, value) => handleFilterChange('facility', value)}
                        getOptionLabel={(option) => option.value}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        renderInput={(params) => <TextField {...params} label="Search Facility" />}
                        renderOption={(props, option) => <li {...props} style={{ cursor: 'pointer' }} key={option._id} className="py-1 ps-3 menu-drop-item"> {option.value} </li>}

                    />
                </div>}
                {isSkeleton ? <div className="px-2">
                    <Skeleton variant="rounded" width={100} height={36} />
                </div> : <div className="px-2 textfiled">
                    <TextField
                        value={locationIdInput || ""}
                        onChange={(event) => handleFilterChange('location_id', event.target.value)}
                        size="small"
                        variant="outlined"
                        label="Search Location id"
                        type="text"
                    />
                </div>}
                {isSkeleton ? <div className="px-2">
                    <Skeleton variant="rounded" width={100} height={36} />
                </div> : <div className="px-2 textfiled">
                    <TextField
                        value={facilityMappingId || ""}
                        onChange={(event) => handleFilterChange('facility_mapping_id', event.target.value)}
                        size="small"
                        variant="outlined"
                        label="Search Facility Mapping"
                        type="text"
                    />
                </div>}

                {isSkeleton ? <div className="px-2">
                    <Skeleton variant="rounded" width={100} height={36} />
                </div> : <div className="px-2 textfiled">
                    <TextField
                        value={punchString || ""}
                        onChange={(event) => handleFilterChange('punch_string', event.target.value)}
                        size="small"
                        variant="outlined"
                        label="Search Punch String"
                        type="text"
                        sx={{ width: "100%" }}
                    />
                </div>}


            </div>
        </div>
    );
};
export default ShiftFilter;